const actions = {
  SET_STATE: 'chart/SET_STATE',

  GET_USER_ACCESS_LEVEL: 'chart/GET_USER_ACCESS_LEVEL',

  GET_CONCESSION_YEARLY_INVOICES: 'chart/GET_CONCESSION_YEARLY_INVOICES',

  GET_CONCESSION_MONTH_INVOICE: 'chart/GET_CONCESSION_MONTH_INVOICE',
  GET_CONCESSION_MONTHLY_INVOICES: 'chart/GET_CONCESSION_MONTHLY_INVOICES',

  GET_STATE_YEARLY_INVOICES: 'chart/GET_STATE_YEARLY_INVOICES',
  GET_STATE_YEAR_INVOICE: 'chart/GET_STATE_YEAR_INVOICE',

  GET_STATE_MONTHLY_INVOICES: 'chart/GET_STATE_MONTHLY_INVOICES',

  GET_PBT_YEARLY_INVOICES: 'chart/GET_PBT_YEARLY_INVOICES',
  GET_PBT_YEAR_INVOICE: 'chart/GET_PBT_YEAR_INVOICE',

  GET_KPI_COUNT_AND_PENALTY_AMOUNT: 'chart/GET_KPI_COUNT_AND_PENALTY_AMOUNT',
  GET_INVOICE_AMOUNTS: 'chart/GET_INVOICE_AMOUNTS',
  GET_INVOICE_DETAILS: 'chart/GET_INVOICE_DETAILS',
  GET_CUMULATIVE_INVOICE_AMOUNT: 'chart/GET_CUMULATIVE_INVOICE_AMOUNT',
}

export default actions
