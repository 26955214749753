const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  REGISTER: 'user/REGISTER_ACCOUNT',
  VERIFYEMAILNEWACCOUNT: 'user/VERIFY_EMAIL_NEWACCOUNT',
  RESETPASSWORD: 'user/RESET_PASSWORD',
  UPDATEPASSWORDBYFORGOT: 'user/UPDATE_PASSWORD_BYFORGOT',
  EDIT_PROFILE: 'user/EDIT_PROFILE',
  EDIT_PASSWORD: 'user/EDIT_PASSWORD',
  CHECK_JWT: 'user/CHECK_JWT',
}

export default actions
