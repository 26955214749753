import axios from 'axios'
import stringInject from 'stringinject'
import {
  getAllUserConfURL,
  updateUser,
  activateUserUrl,
  concessionsURL,
  patchConcessionsURL,
  getRatesURL,
  getRatesCountURL,
  updateRatesByIdURL,
  addRatesURL,
} from './apiUrl'

export async function getAllUserSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(getAllUserConfURL)
      .then(response => resolve(response.data.data))
      .catch(error => reject(error.response))
  })
}

export async function updateUserSvc(userId, obj) {
  return new Promise((resolve, reject) => {
    axios
      .patch(updateUser + userId, obj)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function activateUserSvc(obj) {
  return new Promise((resolve, reject) => {
    axios
      .patch(activateUserUrl, obj)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

// config: ORGANIZATION/CONCESSIONS
export async function retrieveConcessionSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(concessionsURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function patchConcessionSvc(obj, concessionId) {
  return new Promise((resolve, reject) => {
    axios
      .patch(stringInject(patchConcessionsURL, [concessionId]), obj)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getRatesSvc(rowLimit, nextPage, columnsFilter) {
  // console.log('rowLimit', rowLimit, nextPage)
  const cFilter = typeof columnsFilter !== 'undefined' ? String('&').concat(columnsFilter) : ''
  return new Promise((resolve, reject) => {
    axios
      .get(stringInject(getRatesURL, [rowLimit, nextPage, cFilter]))
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getRatesCountSvc(columnsFilter) {
  const whereCond = typeof columnsFilter !== 'undefined' ? String('?').concat(columnsFilter) : ''
  return new Promise((resolve, reject) => {
    axios
      .get(stringInject(getRatesCountURL, [whereCond]))
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function updateRatesById(id, payload) {
  return new Promise((resolve, reject) => {
    axios
      .patch(updateRatesByIdURL + id, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function addRatesSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post(addRatesURL, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export default {}
