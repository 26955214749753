import actions from './actions'

const initialState = {
  concessionYearlyInvoices: {
    data: { labels: [], datasets: { 'Alam Flora': [], SWM: [], 'Environment Idaman': [] } },
  },
  concessionMonthlyInvoices: {
    data: {
      months: [],
      af: [],
      swm: [],
      ei: [],
    },
  },
  stateYearlyInvoices: {
    data: {
      invoiceAmounts: { labels: [], datasets: [] },
      penaltyAmounts: { labels: [], datasets: [] },
    },
  },
  stateYearInvoice: {
    invoice_amount: { labels: [], datasets: [] },
    sst_amount: { labels: [], datasets: [] },
    penalty_amount: { labels: [], datasets: [] },
  },
  stateMonthlyInvoices: {
    data: {
      invoiceAmounts: { labels: [], datasets: [] },
      penaltyAmounts: { labels: [], datasets: [] },
    },
  },
  concessionMonthInvoice: {
    data: {
      byActivities: { labels: [], datasets: [] },
      byCategories: { labels: [], datasets: [] },
      byPBTs: { labels: [], datasets: [] },
    },
  },
  pbtYearlyInvoices: { labels: [], datasets: [] },
  pbtYearInvoice: {
    invoiceAmounts: { labels: [], datasets: [] },
    penaltyAmounts: { labels: [], datasets: [] },
  },
  kpiCountAndPenaltyAmount: [],
  invoiceAmounts: [],
  invoiceDetails: [],
  cumulativeInvoiceAmounts: {
    data: {
      states: [],
    },
  },
  userAccessLevel: [],
}

export default function chartReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
