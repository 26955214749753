import { all, takeEvery, takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import {
  login,
  currentAccount,
  currentAccountRole,
  registerAccount,
  verifyEmailNewAccount,
  resetPasswordSvc,
  updatePasswordByForgotSvc,
  editUserProfileSvc,
  editPasswordSvc,
  getEpochSvc,
} from 'services/user'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import actions from './actions'

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const { email, password, googleToken: token } = payload
    const success = yield call(login, email, password, token)
    yield localStorage.setItem('access_token', success.access_token)
    yield localStorage.setItem('refresh_token', success.refresh_token)
    // yield axios.defaults.baseURL = 'localhost:3000';
    yield (axios.defaults.headers.common.Authorization = success.access_token)
    yield (axios.defaults.headers.post['Content-Type'] = 'application/json')
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield put({
      type: 'user/SET_STATE',
      userInfo: success.me,
    })
    const { name: roles } = success.roles[0]
    console.log('roles:', roles)
    if (roles === 'admin') {
      yield history.push('/dashboard')
    } else if (roles === 'swkerani') {
      yield history.push('/dashboard')
    } else if (roles === 'swbkw') {
      yield history.push('/dashboard')
    } else if (roles === 'swpc') {
      yield history.push('/dashboard')
    } else if (roles === 'swppk') {
      yield history.push('/dashboard')
    } else if (roles === 'konsesimm') {
      yield history.push('/dashboard')
    } else if (roles === 'konsesikw') {
      yield history.push('/dashboard')
    } else if (roles === 'swpn') {
      yield history.push('/dashboard')
    } else if (roles === 'swpbtm') {
      yield history.push('/adjustment-unscheduled/btm')
    } else if (roles === 'swpbsd') {
      yield history.push('/adjustment-unscheduled/bsd')
    } else if (roles === 'swpbkw') {
      yield history.push('/adjustment-unscheduled/bkw')
    } else if (roles === 'swpbkw') {
      yield history.push('/adjustment-unscheduled/bkw')
    } else if (roles === 'swtkpe') {
      yield history.push('/adjustment-unscheduled/tkpe')
    } else if (roles === 'swkpe') {
      yield history.push('/adjustment-unscheduled/kpe')
    } else if (roles === 'swpbpk') {
      yield history.push('/dashboard')
    } else if (roles === 'swpicbtm') {
      yield history.push('/adjustment-unscheduled/pic-btm')
    } else if (roles === 'swpicbsd') {
      yield history.push('/adjustment-unscheduled/pic-bsd')
    } else {
      yield history.push('/payment/invoice')
    }
    notification.success({
      message: 'Log Masuk',
      description: 'Selamat datang ke SPBP',
    })

    yield put({
      type: 'chart/GET_USER_ACCESS_LEVEL',
      payload: {
        data: [],
      },
    })
  } catch (e) {
    // const { message: description } = e.response.data.error
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Log Masuk',
      description: 'Sila masukkan alamat emel & katalaluan yang sah.',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  const accessToken = yield localStorage.getItem('access_token')
  yield (axios.defaults.headers.common.Authorization = accessToken)
  yield (axios.defaults.headers.post['Content-Type'] = 'application/json')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      tabValue: 1,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const {
      id,
      email,
      fullname,
      mykad,
      username,
      concession_id: concessionId,
      no_phone: noPhone,
      concessions,
    } = response
    // console.log('token:', accessToken)

    const responseRole = yield call(currentAccountRole, id)
    if (responseRole) {
      const { name, description } = responseRole[0]

      yield put({
        type: 'user/SET_STATE',
        payload: {
          id,
          fullname,
          mykad,
          username,
          concessionId,
          email,
          avatar: '',
          authorized: true,
          loading: false,
          noPhone,
          role: name,
          roleDescription: description,
          concessions,
        },
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
  // yield put({
  //   type: 'user/CHECK_JWT',
  // })
}

export function* LOGOUT() {
  // yield call(logout)
  yield localStorage.removeItem('access_token')
  yield localStorage.removeItem('refresh_token')
  yield localStorage.removeItem('userId')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      fullname: '',
      username: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
  yield history.replace('/system/login')
}

export function* REGISTER_ACCOUNT({ payload }) {
  const {
    fullname,
    username,
    password1,
    password2,
    email2: email,
    roleId,
    googleToken,
    mykad,
    concession_id: concessionId,
    tugasan_id: tungasanId,
    state_id: stateId,
  } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  try {
    const response = yield call(registerAccount, {
      fullname,
      username,
      password1,
      password2,
      email,
      roleId,
      googleToken,
      mykad,
      concession_id: concessionId,
      tugasan_id: tungasanId,
      state_id: stateId,
    })
    if (response) {
      notification.warning({
        message: 'Daftar Akaun',
        description:
          'Pendaftaran berjaya, anda akan menerima emel setelah Admin membuat pengesahan',
      })
    }
  } catch (e) {
    const { message: description } = e.data.error
    notification.warning({
      message: 'Daftar Akaun',
      description,
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* VERIFYEMAILNEWACCOUNT({ payload }) {
  const { token } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      register: {
        verifyError: [],
      },
    },
  })
  if (token) {
    try {
      const response = yield call(verifyEmailNewAccount, token)
      yield console.log('response', response)
    } catch (e) {
      console.log('error', e)
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          register: {
            success: false,
            message: e.data.error.message,
          },
        },
      })
    }
  } else
    notification.warning({
      message: 'Verifikasi Akaun',
      description: 'Token anda tidak sah!',
    })
}

export function* FUNCRESETPASSWORD({ payload }) {
  try {
    console.log('payload', payload)
    const { email } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    // console.log('email', email)
    const response = yield call(resetPasswordSvc, email)
    if (response) {
      notification.success({
        message: 'Tukar Katalaluan',
        description: 'Sila semak email anda',
      })
    }
    console.log('response:', response)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (e) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Tukar Katalaluan',
      description: 'Katalaluan gagal untuk di set semula, sila semak',
    })
  }
}

export function* FUNCUPDATEPASSWORDBYFORGOT({ payload }) {
  try {
    const { password1, password2, tokenId } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    const response = yield call(updatePasswordByForgotSvc, password1, password2, tokenId)
    if (response) {
      notification.success({
        message: 'Berjaya Dikemaskini',
        description: 'Kataluan anda telah dikemaskini',
      })
    }
    yield history.push('/system/login')
  } catch (e) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    const { message: description, statusCode } = e.data.error
    notification.warning({
      message: 'Tukar Katalaluan',
      description,
    })
    if (statusCode === '400') {
      // only http error 400 will divert to login page
      yield history.push('/system/login')
    }
  }
}

export function* FUNCEDIT_USERPROFILE({ payload }) {
  try {
    yield put({
      type: 'user/SET_STATA',
      payload: {
        loading: true,
      },
    })

    const response = yield call(editUserProfileSvc, payload)
    if (response) {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      notification.success({
        message: 'Profil Pengguna',
        description: 'Maklumat anda telah dikemaskini',
      })
    }
    yield put({
      type: 'user/SET_STATA',
      payload: {
        loading: false,
      },
    })
  } catch (e) {
    yield put({
      type: 'user/SET_STATA',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

export function* FUNCEDIT_PASSWORD({ payload }) {
  try {
    yield {
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    }

    const response = yield call(editPasswordSvc, payload)
    if (response) {
      notification.success({
        message: 'Kemaskini Katalaluan',
        description: 'katalaluan anda telah dikemaskini',
      })
      yield put({
        type: 'user/SET_STATA',
        payload: {
          loading: false,
        },
      })
    }
  } catch (e) {
    yield put({
      type: 'user/SET_STATA',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

export function* FUNC_CHECKJWT() {
  const accessToken = yield localStorage.getItem('access_token')
  if (accessToken) {
    const { currentTime: currentTimeServer } = yield call(getEpochSvc)
    const decodedToken = jwt.decode(accessToken, { complete: true })
    // const currentTime = new Date().getTime() / 1000;
    // console.log('currentTime', currentTime, currentTimeServer, decodedToken.payload.exp);
    if (currentTimeServer > decodedToken.payload.exp) {
      yield put({
        type: 'user/LOGOUT',
      })
      notification.info({
        message: 'Log masuk',
        description: 'Sesi anda telah tamat tempoh, sila log masuk semula',
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.REGISTER, REGISTER_ACCOUNT),
    takeLatest(actions.VERIFYEMAILNEWACCOUNT, VERIFYEMAILNEWACCOUNT),
    takeLatest(actions.RESETPASSWORD, FUNCRESETPASSWORD),
    takeLatest(actions.UPDATEPASSWORDBYFORGOT, FUNCUPDATEPASSWORDBYFORGOT),
    takeLatest(actions.EDIT_PROFILE, FUNCEDIT_USERPROFILE),
    takeLatest(actions.EDIT_PASSWORD, FUNCEDIT_PASSWORD),
    takeLatest(actions.CHECK_JWT, FUNC_CHECKJWT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
