/* eslint-disable no-unused-vars */

import axios from 'axios'
import {
  getConcessionYearlyInvoicesURL,
  getConcessionMonthlyInvoicesURL,
  getStateYearlyInvoicesURL,
  getStateYearlyPenaltiesURL,
  getStateMonthlyInvoicesURL,
  getPbtYearlyInvoicesURL,
  getConcessionMonthInvoiceURL,
  getStateYearInvoiceURL,
  getPbtYearInvoiceURL,
  getKpiCountAndPenaltyAmountURL,
  getInvoiceAmountsURL,
  getInvoiceDetailsURL,
  getCumulativeInvoiceAmountURL,
  getUserAccessLevelURL,
} from './apiUrl'

export default {}

export async function getConcessionYearlyInvoicesSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(getConcessionYearlyInvoicesURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getConcessionMonthlyInvoicesSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(getConcessionMonthlyInvoicesURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getStateYearlyInvoicesSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getStateYearlyInvoicesURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getStateYearlyPenaltiesSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getStateYearlyPenaltiesURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getStateMonthlyInvoicesSvc(concessionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(getStateMonthlyInvoicesURL, {
        params: {
          concessionId,
        },
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getPbtYearlyInvoicesSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getPbtYearlyInvoicesURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getConcessionMonthInvoiceSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getConcessionMonthInvoiceURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getStateYearInvoiceSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getStateYearInvoiceURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getPbtYearInvoiceSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getPbtYearInvoiceURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getKpiCountAndPenaltyAmountSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getKpiCountAndPenaltyAmountURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getInvoiceAmountsSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getInvoiceAmountsURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getInvoiceDetailsSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getInvoiceDetailsURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getCumulativeInvoiceAmountSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getCumulativeInvoiceAmountURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getUserAccessLevelSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .get(getUserAccessLevelURL, {
        params: payload,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}
