import actions from './actions'

const initialState = {
  users: [],
  concessions: [],
  rates: [],
  ratesCount: 0,
  loading: false,
}

export default function configsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_CONCESSIONS:
      return {
        ...state,
        loading: action.payload.loading,
        concessions: state.concessions.map(content =>
          content.id === action.payload.concessions.id ? action.payload.concessions : content,
        ),
      }
    case actions.MERGE_RATES:
      return {
        ...state,
        loading: action.payload.lading,
        rates: state.rates.map(content =>
          content.id === action.payload.rate.id ? action.payload.rate : content,
        ),
      }
    case actions.APPEND_RATES:
      return {
        ...state,
        loading: action.payload.loading,
        rates: [...state.rates, action.payload],
      }
    default:
      return state
  }
}
