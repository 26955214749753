import actions from './actions'

const initialState = {
  concessions: [],
  state: [],
  tugasan: [],
  stateByPbt: [],
  codeAdjustmentStatus: [],
  loading: false,
}

export default function kodReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      // console.log('action.payload', action.payload)
      return { ...state, ...action.payload }
    default:
      return state
  }
}
