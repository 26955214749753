// import firebase from 'firebase/app'
import axios from 'axios'
import stringInject from 'stringinject'
import {
  loginURL,
  meURL,
  userRoleURL,
  registerAccountURL,
  verifyEmailNewAccountURL,
  resetPasswordURL,
  updatePasswordByForgotURL,
  editProfileURL,
  editPasswordURL,
  getEpochURL,
} from './apiUrl'

export default {}

// token = google recaptcha token
export async function login(email, password, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(loginURL, { email, password, token })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export async function currentAccount() {
  // let userLoaded = false
  return axios
    .get(meURL)
    .then(res => res.data)
    .catch(() => false)
}

export async function currentAccountRole(userId) {
  return axios
    .get(stringInject(userRoleURL, { userId }))
    .then(res => res.data)
    .catch(() => false)
}

export async function logout() {
  return true
}

export async function registerAccount(...obj) {
  // return axios.post(registerAccountURL, obj[0]);
  return new Promise((resolve, reject) => {
    axios
      .post(registerAccountURL, obj[0])
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => reject(error.response))
  })
}

export async function verifyEmailNewAccount(token) {
  return new Promise((resolve, reject) => {
    axios
      .post(verifyEmailNewAccountURL, { token })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response))
  })
}

export async function resetPasswordSvc(email) {
  return new Promise((resolve, reject) => {
    axios
      .post(resetPasswordURL, { email })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response))
  })
}

export async function updatePasswordByForgotSvc(password1, password2, tokenId) {
  return new Promise((resolve, reject) => {
    axios
      .post(updatePasswordByForgotURL, { password1, password2, tokenId })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error.response))
  })
}

export async function editUserProfileSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .patch(editProfileURL, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function editPasswordSvc(payload) {
  return new Promise((resolve, reject) => {
    axios
      .patch(editPasswordURL, payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function getEpochSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(getEpochURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}
