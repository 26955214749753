const actions = {
  SET_STATE: 'kod/SET_STATE',
  GET_KOD_KONSESI: 'kod/GET_KOD_KONSESI',
  GET_KOD_NEGERI: 'kod/GET_KOD_NEGERI',
  GET_KOD_TUGASAN: 'kod/GET_KOD_TUGASAN',
  GET_KOD_ACTIVITIES: 'kod/GET_KOD_ACTIVITIES',
  GET_KOD_STATEBYPBTS: 'kod/GET_KOD_STATEBYPBTS',
  GET_KOD_ADJUSTMENT_STATUS: 'kod/GET_KOD_ADJUSTMENT_STATUS',
  GET_KOD_ADJUSTMENT_UNSCHEDULE_STATUS: 'kod/GET_KOD_ADJUSTMENT_UNSCHEDULE_STATUS',
}

export default actions
