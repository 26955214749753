import axios from 'axios'
import { activitiesURL, parksURL, pbtURL, stateURL, schemeURL, streetURL } from './apiUrl'

export async function kodActivitiesSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(activitiesURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodParksSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(parksURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodPbtSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(pbtURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodStateSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(stateURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodSchemeSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(schemeURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodStreetSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(streetURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export default {}
