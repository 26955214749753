import axios from 'axios'
import stringInject from 'stringinject'
import {
  concessionsURL,
  statesURL,
  concessionsByTaskURL,
  activitiesURL,
  stateByPbtURL,
  codeAdjStatusURL,
  codeAdjUnscheduledStatusURL,
} from './apiUrl'

export async function kodKonsesi() {
  return new Promise((resolve, reject) => {
    axios
      .get(concessionsURL)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => reject(error))
  })
}

export async function kodNegeri() {
  return new Promise((resolve, reject) => {
    axios
      .get(statesURL)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => reject(error))
  })
}

export async function kodTugasFn(concessionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(stringInject(concessionsByTaskURL, { concessionId }))
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => reject(error))
  })
}

export async function kodActivitiesSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(activitiesURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodStateByPbtsSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(stateByPbtURL)
      .then(response => resolve(response.data.data.obj))
      .catch(error => reject(error.response))
  })
}

export async function kodAdjustmentStatusSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(codeAdjStatusURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function kodAdjustmentUnscheduleStatusSvc() {
  return new Promise((resolve, reject) => {
    axios
      .get(codeAdjUnscheduledStatusURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export default {}
