let mainURL = '/api/'
if (process.env.REACT_APP_NODE_ENV === 'staging')
  mainURL = 'https://sso.kubenative.com.my:8443/api/'

// PAYMENT
module.exports.getPaymentURL = `${mainURL}paymentInvoices/getActiveInvoice`
module.exports.updateInvoiceRefNoPaymentURL = `${mainURL}paymentInvoices/updateInvoiceWithRefNo`
// VALIDATION
module.exports.verifyInvoiceByKewURL = `${mainURL}paymentInvoices/`
// AZRI TRAINING
module.exports.insertPaymentURL = `${mainURL}paymentInvoices`
module.exports.updatePaymentURL = `${mainURL}paymentInvoices/`
module.exports.deletePaymentURL = `${mainURL}paymentInvoices/`

// references
module.exports.getActivitiesURL = `${mainURL}pdibactivities`
module.exports.parksURL = `${mainURL}pdibparks`
module.exports.pbtURL = `${mainURL}pbts`
module.exports.stateURL = `${mainURL}states`
module.exports.schemeURL = `${mainURL}schemePortion`
module.exports.streetURL = `${mainURL}streets`

// KOD
module.exports.concessionsURL = `${mainURL}concessions?filter[include]=states`
module.exports.activitiesURL = `${mainURL}pdibactivities`
module.exports.codeAdjStatusURL = `${mainURL}codeAdjustmentStatus?filter[where][status]=1`
module.exports.codeAdjUnscheduledStatusURL = `${mainURL}codeAdjustmentUnscheduledStatus?filter[where][status]=1`
// this will use string inject to append value for concessionId
// https://stackoverflow.com/a/44984040
module.exports.concessionsByTaskURL = `${mainURL}concessions/{concessionId}/codeTasks` // 0 = concessionId
// EOF
module.exports.statesURL = `${mainURL}pdibstates?filter[include]=pdibpbts`
module.exports.tugasURL = `${mainURL}codeTasks?filter[where][concession_id]=`
module.exports.stateByPbtURL = `${mainURL}pdibstates/getStateFollowByPBT`

// CONFIGURATION
module.exports.patchConcessionsURL = `${mainURL}concessions/{0}?filter[include]=states`
module.exports.getRatesURL = `${mainURL}rates?filter[limit]={0}&filter[skip]={1}{2}&filter[order]=explaination ASC`
module.exports.getRatesCountURL = `${mainURL}rates/countRow{0}`
module.exports.updateRatesByIdURL = `${mainURL}rates/`
module.exports.addRatesURL = `${mainURL}rates`
// EOF CONFIGURATION

// user Model
module.exports.loginURL = `${mainURL}users/login`
module.exports.refreshURL = `${mainURL}users/refresh`
module.exports.meURL = `${mainURL}users/me?filter[include]=concessions&filter[include]=roles`
module.exports.getEpochURL = `${mainURL}users/getEpoch`
module.exports.userRoleURL = `${mainURL}users/{userId}/roles`
module.exports.resetPasswordURL = `${mainURL}users/forgotPassword`
module.exports.updatePasswordByForgotURL = `${mainURL}users/updatePasswordByForgot`
module.exports.getAllUserConfURL = `${mainURL}users/getAllUsers`
module.exports.updateUser = `${mainURL}users/`
module.exports.activateUserUrl = `${mainURL}users/approveNewUser`
module.exports.editProfileURL = `${mainURL}users/updateProfile`
module.exports.editPasswordURL = `${mainURL}users/updatePassword`
// URL FOR REGISTRATION
module.exports.registerAccountURL = `${mainURL}users/registerAccount`
module.exports.verifyEmailNewAccountURL = `${mainURL}users/emailUserVerify`

// CHART
/*
  year:number (required)
  month:number (required)
  concessionId:number
  stateId:number
  pbtId:number

  + To get invoices by concessions, leave concessionId blank or set to 0
  + To get invoices by state, set stateId to 0
  + To get invoices by pbt, set pbtId to 0
*/
module.exports.getInvoiceAmountsURL = `${mainURL}chart/getInvoiceAmounts`

/*
  year:number
  month:number
  concessionId:number
  stateId:number
*/
module.exports.getInvoiceDetailsURL = `${mainURL}chart/getInvoiceDetails`

module.exports.getCumulativeInvoiceAmountURL = `${mainURL}chart/getCumulativeInvoiceAmount`

// --concession
module.exports.getConcessionYearlyInvoicesURL = `${mainURL}chart/getConcessionYearlyInvoices`
module.exports.getConcessionMonthlyInvoicesURL = `${mainURL}chart/getConcessionMonthlyInvoices`
module.exports.getConcessionMonthInvoiceURL = `${mainURL}chart/getConcessionMonthInvoice`

// --state
module.exports.getStateYearlyInvoicesURL = `${mainURL}chart/getStateYearlyInvoices`
module.exports.getStateYearInvoiceURL = `${mainURL}chart/getStateYearInvoice`

module.exports.getStateMonthlyInvoicesURL = `${mainURL}chart/getStateMonthlyInvoices`
// --pbt
module.exports.getPbtYearlyInvoicesURL = `${mainURL}chart/getPbtYearlyInvoices`
module.exports.getPbtYearInvoiceURL = `${mainURL}chart/getPbtYearInvoice`
// --kpi
module.exports.getKpiCountAndPenaltyAmountURL = `${mainURL}chart/getKpiCountAndPenaltyAmount`

module.exports.getUserAccessLevelURL = `${mainURL}chart/getUserAccessLevel`
