import { all, takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  kodActivitiesSvc,
  kodParksSvc,
  kodPbtSvc,
  kodSchemeSvc,
  kodStateSvc,
  kodStreetSvc,
} from 'services/references'
import actions from './actions'

export function* FUNCGET_ACTIVITIES() {
  try {
    const response = yield call(kodActivitiesSvc)
    yield put({
      type: 'references/SET_STATE',
      payload: {
        data: response,
      },
    })
  } catch (e) {
    notification.warning({
      message: 'Kod Aktiviti',
      description: 'Sistem gagal mendapatkan senarai aktiviti.',
    })
  }
}

export function* FUNCGET_PARKS() {
  try {
    const response = yield call(kodParksSvc)
    yield put({
      type: 'references/SET_STATE',
      payload: {
        data: response,
      },
    })
  } catch (e) {
    // notification.warning({
    // message: 'kod Taman',
    // description: 'Sistem gagal mendapatkan senarai taman.',
    // })
  }
}

export function* FUNCGET_PBT() {
  try {
    const response = yield call(kodPbtSvc)
    yield put({
      type: 'references/SET_STATE',
      payload: {
        data: response,
      },
    })
  } catch (e) {
    // notification.warning({
    //   message: 'kod PBT',
    //   description: 'Sistem gagal mendapatkan senarai kod PBT.',
    // })
  }
}

export function* FUNCGET_STATE() {
  try {
    const response = yield call(kodStateSvc)
    yield put({
      type: 'references/SET_STATE',
      payload: {
        data: response,
      },
    })
  } catch (e) {
    notification.warning({
      message: 'kod Negeri',
      description: 'Sistem gagal mendapatkan senarai kod Negeri.',
    })
  }
}

export function* FUNCGET_SCHEME() {
  try {
    const response = yield call(kodSchemeSvc)
    yield put({
      type: 'references/SET_STATE',
      payload: {
        data: response,
      },
    })
  } catch (e) {
    notification.warning({
      message: 'kod Skema',
      description: 'Sistem gagal mendapatkan senarai kod skema.',
    })
  }
}

export function* FUNCGET_STREET() {
  try {
    const response = yield call(kodStreetSvc)
    yield put({
      type: 'references/SET_STATE',
      payload: {
        data: response,
      },
    })
  } catch (e) {
    notification.warning({
      message: 'kod jalan',
      description: 'Sistem gagal mendapatkan senarai jalan.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST_ACTIVITIES, FUNCGET_ACTIVITIES),
    takeLatest(actions.GET_LIST_PARKS, FUNCGET_PARKS),
    takeLatest(actions.GET_LIST_PBT, FUNCGET_PBT),
    takeLatest(actions.GET_LIST_SCHEME, FUNCGET_SCHEME),
    takeLatest(actions.GET_LIST_STATE, FUNCGET_STATE),
    takeLatest(actions.GET_LIST_STREET, FUNCGET_STREET),
  ])
}
