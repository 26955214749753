/* eslint-disable import/prefer-default-export */
/* eslint-disable */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({ component: Component, user: { role }, roles, debug, ...rest }) => {
  return (
    <Route
      key={rest.path}
      {...rest}
      render={props => {
        if (debug) {
          roles.push('admin')
        }
        if (roles.includes(role)) {
          return <Component {...props} />
        }
        return (
          <Redirect
            to={{
              pathname: '/system/403',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }}
    />
  )
}

export default ProtectedRoute
