import actions from './actions'

const initialState = {
  count: 0,
  data: [],
  verifyInvoice: {
    showModal: false,
    data: {},
    confirmLoading: false,
  },
  verifyInvoiceByKew: {
    showModal: false,
    data: {},
    confirmLoading: false,
  },
  // azri training
  deletePayment: {
    showModal: false,
    data: {},
    confirmLoading: false,
  },
  updatePayment: {
    showModal: false,
    data: {},
    confirmLoading: false,
  },
  insertPayment: {
    showModal: false,
    data: {},
    confirmLoading: false,
  },
}

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
