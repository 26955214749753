import React, { Fragment } from 'react'
import { connect } from 'react-redux'

const ConcessionLogo = ({ user }) => {
  const { concessionId } = user
  return (
    <Fragment>
      {concessionId === 1 && (
        <img
          src="resources/images/concessions/concession_alamflora.png"
          alt="afsb-logo"
          width="140px"
        />
      )}
      {concessionId === 2 && (
        <img src="resources/images/concessions/concession_swm.jpg" alt="swm-logo" width="165px" />
      )}
      {concessionId === 3 && (
        <img
          src="resources/images/concessions/concession_eidaman.png"
          alt="eidaman-logo"
          width="80px"
        />
      )}
    </Fragment>
  )
}

const ConcessionLogoComponent = connect(({ user }) => ({ user }))(ConcessionLogo)

export default ConcessionLogoComponent
