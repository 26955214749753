/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import actions from './actions'

export function* setState(action) {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: action.payload,
  })
}

export default function* rootSaga() {
  yield all([setState])
}
