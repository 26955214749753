import { all, takeLatest, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  kodKonsesi,
  kodNegeri,
  kodTugasFn,
  kodActivitiesSvc,
  kodStateByPbtsSvc,
  kodAdjustmentStatusSvc,
  kodAdjustmentUnscheduleStatusSvc,
} from 'services/kod'
import actions from './actions'

export function* GET_KONSESI() {
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(kodKonsesi)
  // console.log('success', success)
  if (success) {
    // console.log('success', success)
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        concessions: success,
      },
    })
  }
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_NEGERI() {
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(kodNegeri)
  // console.log('success', success)
  if (success) {
    // console.log('success', success)
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        state: success,
      },
    })
  }
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_TUGASAN({ payload }) {
  const kodTugas = payload
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: true,
      tugasan: [],
    },
  })
  const success = yield call(kodTugasFn, kodTugas)
  if (success) {
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        tugasan: success,
      },
    })
  }
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* FUNCGET_ACTIVITIES() {
  try {
    const response = yield call(kodActivitiesSvc)
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        activities: response,
      },
    })
  } catch (e) {
    notification.warning({
      message: 'kod Aktiviti',
      description: 'Sistem gagal mendapatkan senarai aktiviti.',
    })
  }
}

export function* FUNCGET_STATEBYPBTS() {
  try {
    const response = yield call(kodStateByPbtsSvc)
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        statebypbts: response,
      },
    })
  } catch (e) {
    notification.warning({
      message: 'Kod Negeri/PBT',
      description: 'Sistem tidak berjaya mendapatkan senarai negeri/pbt',
    })
  }
}

export function* FUNCGET_ADJUSTMENTCODESTATUS() {
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(kodAdjustmentStatusSvc)
  if (success) {
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        codeAdjustmentStatus: success,
      },
    })
  }
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* FUNCGET_ADJUSTMENTUNSCHEDULECODESTATUS() {
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(kodAdjustmentUnscheduleStatusSvc)
  if (success) {
    yield put({
      type: 'kod/SET_STATE',
      payload: {
        codeAdjustmentUnscheduledStatus: success,
      },
    })
  }
  yield put({
    type: 'kod/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_KOD_KONSESI, GET_KONSESI),
    takeLatest(actions.GET_KOD_NEGERI, GET_NEGERI),
    takeLatest(actions.GET_KOD_TUGASAN, GET_TUGASAN),
    takeLatest(actions.GET_KOD_ACTIVITIES, FUNCGET_ACTIVITIES),
    takeLatest(actions.GET_KOD_STATEBYPBTS, FUNCGET_STATEBYPBTS),
    takeLatest(actions.GET_KOD_ADJUSTMENT_STATUS, FUNCGET_ADJUSTMENTCODESTATUS),
    takeLatest(
      actions.GET_KOD_ADJUSTMENT_UNSCHEDULE_STATUS,
      FUNCGET_ADJUSTMENTUNSCHEDULECODESTATUS,
    ),
    FUNCGET_ADJUSTMENTCODESTATUS(),
    FUNCGET_ADJUSTMENTUNSCHEDULECODESTATUS(),
    GET_NEGERI(), // Load once
    GET_KONSESI(), // Load once
    FUNCGET_ACTIVITIES(), // Load once
  ])
}
