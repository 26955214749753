/* eslint-disable no-unused-vars */
import React from 'react'
// import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
  }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: 'chart/SET_STATE',
      payload: {
        userAccessLevel: {
          data: {},
        },
      },
    })
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  // addCount = () => {
  //   let { count } = this.state
  //   count += 1
  //   this.setState({
  //     count,
  //   })
  // }

  render() {
    const {
      user,
      user: { roleDescription },
    } = this.props
    const { count } = this.state
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>Hi, {user.fullname}</strong>
          {/* <div>
            <strong className="mr-1">
              <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
            </strong>
            Professional
          </div> */}
          <div>
            <strong>{/* <FormattedMessage id="topBar.profileMenu.role" />:{' '} */} </strong>
            {/* {user.role} */}
            {roleDescription}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>Emel: </strong>
            {user.email}
            <br />
            <strong>Telefon: </strong>
            {user.noPhone || '— '}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to="/configuration/profile">
            <i className={`${styles.menuIcon} fe fe-user`} />
            {/* <FormattedMessage id="topBar.profileMenu.editProfile" /> */}
            Profil
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="#" onClick={this.logout}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            Log Keluar
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu}>
        <div className={styles.dropdown}>
          {/* <Badge count={count}> */}
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
          {/* </Badge> */}
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
