import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import payments from './payment/reducers'
import kods from './kod/reducers'
import configs from './configuration/reducers'
import references from './references/reducers'
import chart from './chart/reducers'
import dashboard from './dashboard/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    payments,
    kods,
    configs,
    references,
    chart,
    dashboard,
  })
