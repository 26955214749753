const actions = {
  SET_STATE: 'references/SET_STATE',
  GET_LIST_ACTIVITIES: 'references/GET_LIST_ACTIVITIES',
  GET_LIST_PARKS: 'references/GET_LIST_PARKS',
  GET_LIST_PBT: 'references/GET_LIST_PBT',
  GET_LIST_STATE: 'references/GET_LIST_STATE',
  GET_LIST_SCHEME: 'references/GET_LIST_SCHEME',
  GET_LIST_STREET: 'references/GET_LIST_STREET',
}

export default actions
