/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getInvoice,
  updateInvoiceRefNoPaymentSvc,
  verifyInvoiceByKewSvc,
  deletePaymentSvc,
  updatePaymentSvc,
  insertPaymentSvc,
} from 'services/payment'
import actions from './actions'

export function* getPaymentInvoice() {
  try {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { response: success } = yield call(getInvoice)
    // console.log('success', success)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        count: success.length,
        data: success,
        loading: false,
      },
    })
  } catch (e) {
    // console.log('payment', e)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        count: 0,
        data: [],
        loading: false,
      },
    })
    // notification.warning({
    //   message: e.data.error.statusCode,
    //   description: e.data.error.message,
    // })
  }
}

export function* updateInvoiceRefNoPayment({ verifyInvoice, payload }) {
  // console.log('payload', payload)
  const { invoiceNo } = payload
  try {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        verifyInvoice: {
          ...verifyInvoice,
          confirmLoading: true,
        },
      },
    })
    const { response: success } = yield call(updateInvoiceRefNoPaymentSvc, payload)
    // console.log('success', success)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        verifyInvoice: {
          ...verifyInvoice,
          confirmLoading: false,
          showModal: false,
        },
      },
    })
    notification.success({
      message: `${invoiceNo}`,
      description: 'No. rujukan invois telah dikemaskini',
    })
    yield getPaymentInvoice()
  } catch (e) {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        verifyInvoice: {
          ...verifyInvoice,
          confirmLoading: false,
          showModal: true,
        },
      },
    })
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

export function* verifyInvoiceByKew({ payload, invoiceId }) {
  // console.log('payload', payload)
  const { invoiceNo } = payload
  try {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { response: success } = yield call(verifyInvoiceByKewSvc, invoiceId)
    // console.log('success', success)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: `${invoiceNo}`,
      description: 'Maklumat invois telah disahkan',
    })
    yield getPaymentInvoice()
  } catch (e) {
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

// azri training
export function* deletePayment({ payload, invoiceId }) {
  // console.log('payload', payload)
  const { invoiceNo } = payload
  try {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(deletePaymentSvc, invoiceId)
    // console.log('success', success)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: `${invoiceNo}`,
      description: 'Maklumat invois telah dipadam',
    })
    yield getPaymentInvoice()
  } catch (e) {
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

export function* updatePayment({
  payload,
  invoiceId,
  invoiceNombor,
  invoiceAmount,
  invoiceStatus,
}) {
  console.log('payload', payload)
  const { invoiceNo } = payload
  try {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { response: success } = yield call(
      updatePaymentSvc,
      invoiceId,
      invoiceNombor,
      invoiceAmount,
      invoiceStatus,
    )
    console.log('success', success)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: `${invoiceNo}`,
      description: 'Maklumat invois telah dikemaskini!',
    })
    yield getPaymentInvoice()
  } catch (e) {
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

export function* insertPayment({
  payload,
  invoiceId,
  invoiceNombor,
  invoiceAmount,
  invoiceStatus,
}) {
  console.log('saga js :', invoiceId, invoiceNombor, invoiceAmount, invoiceStatus)
  // id: 147 invoice_no: abc123 invoice_amount_with_sst: 100 invoice_status: 0
  const { invoiceNo } = payload
  try {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const { response: success } = yield call(
      insertPaymentSvc,
      invoiceId,
      invoiceNombor,
      invoiceAmount,
      invoiceStatus,
    )
    console.log('success', success)
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: `Maklumat ${invoiceNo}`,
      description: `Maklumat invois ${invoiceNo} telah diberjaya dimasukkan`,
    })
    yield getPaymentInvoice()
  } catch (e) {
    notification.warning({
      message: e.data.error.statusCode,
      description: e.data.error.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INVOICE, getPaymentInvoice),
    takeLatest(actions.UPDATE_INVOICE_REFNO, updateInvoiceRefNoPayment),
    takeLatest(actions.VERIFY_INVOICE_BY_KEW, verifyInvoiceByKew),
    // azri training
    takeLatest(actions.DELETE_INVOIS, deletePayment),
    takeLatest(actions.UPDATE_INVOIS, updatePayment),
    takeLatest(actions.INSERT_INVOIS, insertPayment),
  ])
}
