const actions = {
  SET_STATE: 'config/SET_STATE',
  GET_ALLUSER: 'config/GET_ALLUSER',
  UPDATE_USER: 'config/UPDATE_USER',
  ACTIVATE_USER: 'config/ACTIVATE_USER',
  GET_CONCESSIONS: 'config/GET_CONCESSIONS',
  PATCH_CONCESSIONS: 'config/PATCH_CONCESSIONS',
  UPDATE_CONCESSIONS: 'config/UPDATE_CONCESSIONS',
  GET_RATES: 'config/GET_RATES',
  UPDATE_RATES: 'config/UPDATE_RATES',
  MERGE_RATES: 'config/MERGE_RATES',
  ADD_RATES: 'config/ADD_RATES',
  APPEND_RATES: 'config/APPEND_RATES',
}

export default actions
