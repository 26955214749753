import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import localforage from 'localforage'
import { PersistGate } from 'redux-persist/integration/react'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import axios from 'axios'
// import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { notification, Icon } from 'antd'
import Cookies from 'js-cookie';

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import initGA from './components/system/GA'

// app styles
import './global.scss'

const persistConfig = {
  key: 'root',
  // storage,
  storage: localforage,
}

// middlewared
const refresh = axios.create({
  withCredentials: true,
  xsrfCookieName: '_csrf'
})
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const persistedReducer = persistReducer(persistConfig, reducers(history))
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger)
}

const store =
  process.env.NODE_ENV === 'production'
    ? createStore(persistedReducer, compose(applyMiddleware(...middlewares)))
    : createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)))

const persistor = persistStore(store)
sagaMiddleware.run(sagas)

initGA()

// if (process.env.NODE_ENV !== "development"){
//   let console = {};
//   console.log = function () {};
// }  

// Function that will be called to refresh authorization
// const refreshAuthLogic = failedRequest => axios.post('/api/users/login', {}).then(tokenRefreshResponse => {
//     localStorage.setItem('access_token', tokenRefreshResponse.data.token);
//     failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.token;
//     return Promise.resolve();
// });
// const refreshAuthLogic = async failedRequest => {

//   const refreshToken = localStorage.getItem('refresh_token');
//   const data = await axios.post('/api/users/login', {
//     grant_type: 'refresh_token',
//     refresh_token: refreshToken
//   });
//   localStorage.setItem('access_token', data.accessToken);
//   localStorage.setItem('refresh_token', data.refreshToken);
//   failedRequest.response.config.headers.Authorization = data.token;
//   return Promise.resolve();
// }

// Instantiate the interceptor (you can chain it as it returns the axios instance)
// createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const httpMethod = ['post','put','update','delete', 'patch'];
    const originalRequest = error.config
    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    const csrfToken = Cookies.get('_csrf') || "null";

    console.log(error.response.config.method, httpMethod.includes(error.response.config.method));
    if (error.response.status == 403 && httpMethod.includes(error.response.config.method)) {
      // send csrf over the air
      axios.defaults.withCredentials = true;
      axios.defaults.xsrfCookieName = '_csrf';
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      return axios(originalRequest);                                 
    }

    if (error.response.status === 401 && Boolean(refreshToken)) {
      // Hace la solicitud de refresco de tokens
      return refresh
        .post(
          '/api/users/login',
          {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
          },
          {
            headers: {
              Authorization: accessToken,
              'Content-Type': 'application/json',
            },
          },
        )
        .then(responseData => {
          // console.log('responseData', responseData)
          // actualiza la información de OAuth
          // setTokens(responseData.data.access_token, responseData.data.refresh_token);
          localStorage.setItem('access_token', responseData.data.accessToken)
          localStorage.setItem('refresh_token', responseData.data.refreshToken)
          axios.defaults.headers.post['X-CSRF-Token'] = Cookies.get('spbp.swcorp.my-csrf');
          axios.defaults.headers.common.Authorization = responseData.data.accessToken
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          originalRequest.headers.Authorization = responseData.data.accessToken
          // re-intenta la solicitud original
          return axios(originalRequest)
        })
        .catch(() => {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          notification.open({
            message: 'Notifikasi',
            description: 'Sesi anda telah tamat tempoh, sila log masuk semula.',
            icon: <Icon type="sound" style={{ color: 'red' }} />,
          })
          history.replace('/system/login')
        })
    }


    return Promise.reject(error)
  },
)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Localization>
        <Router history={history} />
      </Localization>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

history.listen(location => {
  if (process.env.process === 'production') {
    window.ga('set', 'page', location.pathname + location.search)
    window.ga('send', 'pageview')
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
