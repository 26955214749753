import { all, takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getAllUserSvc,
  updateUserSvc,
  activateUserSvc,
  retrieveConcessionSvc,
  patchConcessionSvc,
  getRatesSvc,
  getRatesCountSvc,
  updateRatesById,
  addRatesSvc,
} from 'services/configuration'
import actions from './actions'

export function* FUNCGETALLUSER() {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(getAllUserSvc)
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: false,
        users: response,
      },
    })
  } catch (e) {
    console.log('e', e)
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Senarai Pengguna',
      description: 'Sistem gagal untuk dapatkan senarai pengguna',
    })
  }
}

export function* FUNCUPDATEUSER({ userId, payload }) {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    // eslint-disable-next-line no-unused-vars
    const response = yield call(updateUserSvc, userId, payload)
    yield FUNCGETALLUSER()
    notification.success({
      message: 'Pengguna Sistem',
      description: 'Kemaskini telah berjaya',
    })
  } catch (e) {
    notification.warning({
      message: 'Pengguna Sistem',
      description: 'Maaf, sistem gagal kemaskini!',
    })
  }
}

export function* FUNCACTIVATEUSER({ payload }) {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    // eslint-disable-next-line no-unused-vars
    const response = yield call(activateUserSvc, payload)
    yield FUNCGETALLUSER()
    notification.success({
      message: 'Pengguna Sistem',
      description: 'Id pengguna telah diaktifkan',
    })
  } catch (e) {
    notification.warning({
      message: 'Pengguna Sistem',
      description: 'Maaf, sistem gagal aktifkan pengguna!',
    })
  }
}

export function* FUNCGETCONCESSIONS() {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(retrieveConcessionSvc)
    yield put({
      type: 'config/SET_STATE',
      payload: {
        concessions: response,
        loading: false,
      },
    })
  } catch (e) {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Organisasi & Konsesi',
      description: 'Maaf, tiada capaian mendapatkan data!',
    })
  }
}

export function* FUNCPATCHCONCESSIONS({ payload, concessionId }) {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(patchConcessionSvc, payload, concessionId)

    yield put({
      type: 'config/UPDATE_CONCESSIONS',
      payload: {
        loading: false,
        concessions: response,
      },
    })
    notification.success({
      message: 'Organisasi & Konsesi',
      description: 'Maklumat telah berjaya dikemaskini.',
    })
  } catch (e) {
    notification.warning({
      message: 'Organisasi & Konsesi',
      description: 'Maaf, tiada capaian mengemaskini data!',
    })
  }
}

export function* FUNCGETRATES({ rowLimit, nextPage, columnsFilter, countFilter }) {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(getRatesSvc, rowLimit, nextPage, columnsFilter)
    const countResponse = yield call(getRatesCountSvc, countFilter)
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: false,
        rates: response,
        ratesCount: countResponse.count,
      },
    })
  } catch (e) {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Kadar',
      description: 'Maaf, sistem gagal mendapatkan kadar.',
    })
  }
}

export function* FUNCUPDATERATES_BYID({ payload, id }) {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(updateRatesById, id, payload)
    console.log('response', response)
    yield put({
      type: 'config/MERGE_RATES',
      payload: {
        loading: true,
        rate: response,
        id,
      },
    })
    notification.success({
      message: 'Kadar',
      description: 'Maklumat kadar telah dikemaskini',
    })
  } catch (e) {
    notification.warning({
      message: 'Kadar',
      description: 'Maaf, sistem gagal kemaskini kadar.',
    })
  }
}

export function* FUNC_ADDRATES({ payload }) {
  try {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const response = yield call(addRatesSvc, payload)
    yield put({
      type: 'config/APPEND_RATES',
      payload: response,
    })
    notification.success({
      message: 'Kadar',
      description: 'Tambahan kadar telah berjaya',
    })
  } catch (e) {
    yield put({
      type: 'config/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Kadar',
      description: 'Maaf, sistem gagal tambahan kadar.',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_ALLUSER, FUNCGETALLUSER),
    takeLatest(actions.UPDATE_USER, FUNCUPDATEUSER),
    takeLatest(actions.ACTIVATE_USER, FUNCACTIVATEUSER),
    takeLatest(actions.GET_CONCESSIONS, FUNCGETCONCESSIONS),
    takeLatest(actions.PATCH_CONCESSIONS, FUNCPATCHCONCESSIONS),
    takeEvery(actions.GET_RATES, FUNCGETRATES),
    takeLatest(actions.UPDATE_RATES, FUNCUPDATERATES_BYID),
    takeLatest(actions.ADD_RATES, FUNC_ADDRATES),
  ])
}
