import { all, take } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist/lib/constants'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import payments from './payment/sagas'
import kods from './kod/sagas'
import configs from './configuration/sagas'
import references from './references/sagas'
import chart from './chart/sagas'
import dashboard from './dashboard/sagas'

export default function* rootSaga() {
  // console.log('Waiting for rehydration')
  yield take(REHYDRATE) // Wait for rehydrate to prevent sagas from running with empty store
  // console.log('Rehydrated')
  yield all([
    user(),
    menu(),
    settings(),
    payments(),
    kods(),
    configs(),
    references(),
    chart(),
    dashboard(),
  ])
}
