/* eslint-disable */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Switch from "react-router-transition-switch";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import Layout from "layouts";
import Loader from "components/layout/Loader";
import NotFoundPage from "pages/system/404";
import axios from "axios";
import ProtectedRoute from "./protected-route";

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />
  });

const routes = [
  {
    path: "/kwpsppa/laporan",
    Component: loadable(() => import("pages/kwpsppa/laporan")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/dashboard",
    Component: loadable(() => import("pages/dashboard/statistics/bkw")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "swpc",
      "swppk",
      "konsesimm",
      "konsesikw",
      "swpn",
      "swpbtm",
      "swpbsd",
      "swpbkw",
      "swtkpe",
      "swkpe",
      "swpbpk",
      "swbkw",
      "swpicbtm",
      "swbahagian"
    ],
    exact: true
  },

  // Ecommerce
  {
    path: "/ecommerce/dashboard",
    Component: loadable(() => import("pages/ecommerce/dashboard")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "konsesimm",
      "konsesikw",
      "swpc",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  {
    path: "/ecommerce/orders",
    Component: loadable(() => import("pages/ecommerce/orders")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "konsesimm",
      "konsesikw",
      "swpc",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  {
    path: "/ecommerce/product-catalog",
    Component: loadable(() => import("pages/ecommerce/product-catalog")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "konsesimm",
      "konsesikw",
      "swpc",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  {
    path: "/ecommerce/product-details",
    Component: loadable(() => import("pages/ecommerce/product-details")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "konsesimm",
      "konsesikw",
      "swpc",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  {
    path: "/ecommerce/cart",
    Component: loadable(() => import("pages/ecommerce/cart")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "konsesimm",
      "konsesikw",
      "swpc",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  // spbp
  {
    path: "/payment/invoice",
    Component: loadable(() => import("pages/payment/invoice")),
    roles: [
      "admin",
      "swpegawai",
      "konsesikw",
      "konsesimm",
      "swpbtm",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  {
    path: "/payment/invoice/:invoiceNo",
    Component: loadable(() =>
      import("pages/payment/invoice/invoiceViewTemplate")
    ),
    roles: ["admin", "swpegawai", "swkerani", "swbkw", "swbahagian"],
    exact: true
  },
  {
    path: "/payment/verifyInvoice",
    Component: loadable(() => import("pages/payment/verifyInvoice")),
    roles: ["admin", "swpegawai", "swkerani", "swbkw", "swbahagian"],
    exact: true
  },
  {
    path: "/payment/bypbt",
    Component: loadable(() => import("pages/payment/InvoiceByPBT")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "swpc",
      "swppk",
      "swpicbsd",
      "swpicbtm",
      "swpn",
      "swbkw",
      "swbahagian"
    ],
    exact: true
  },
  // Configuration Pages
  {
    path: "/configuration/users",
    Component: loadable(() => import("pages/configuration/users")),
    roles: ["admin"],
    exact: true
  },
  {
    path: "/configuration/organization",
    Component: loadable(() => import("pages/configuration/organization")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/configuration/rates",
    Component: loadable(() => import("pages/configuration/rates")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/configuration/profile",
    Component: loadable(() => import("pages/configuration/profile")),
    roles: [
      "admin",
      "swpegawai",
      "swkerani",
      "konsesimm",
      "konsesikw",
      "swpc",
      "swbkw"
    ],
    exact: true
  },
  {
    path: "/references/activity",
    Component: loadable(() => import("pages/references/activity")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/references/parks",
    Component: loadable(() => import("pages/references/parks")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/references/pbt",
    Component: loadable(() => import("pages/references/pbt")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/references/state",
    Component: loadable(() => import("pages/references/state")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/references/scheme",
    Component: loadable(() => import("pages/references/scheme")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/references/street",
    Component: loadable(() => import("pages/references/street")),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/adjustment/swcorp",
    Component: loadable(() => import("pages/adjustment/swcorp")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/adjustment/swcorp/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/swcorp/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpegawai", "swbkw"],
    exact: true
  },
  {
    path: "/adjustment/bkw",
    Component: loadable(() => import("pages/adjustment/bkw")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbkw"],
    exact: true
  },
  {
    path: "/adjustment/swpbkw",
    Component: loadable(() => import("pages/adjustment/swpbkw")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpbkw"],
    exact: true
  },
  {
    path: "/adjustment/swbahagian",
    Component: loadable(() => import("pages/adjustment/swbahagian")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbahagian"],
    exact: true
  },
  {
    path: "/adjustment/bkw/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/bkw/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbkw", "swbahagian"],
    exact: true
  },
  {
    path: "/adjustment/ppk/:key?",
    Component: loadable(() => import("pages/adjustment/ppk")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swppk"],
    exact: true
  },
  {
    path: "/adjustment/ppk/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/ppk/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swppk"],
    exact: true
  },
  {
    path: "/adjustment/admin/:key?",
    Component: loadable(() => import("pages/adjustment/admin")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin"],
    exact: true
  },
  {
    path: "/adjustment/swpbtm/:key?",
    Component: loadable(() => import("pages/adjustment/swpbtm")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpbtm"],
    exact: true
  },
  {
    path: "/adjustment/swpbtm/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/swpbtm/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpbtm"],
    exact: true
  },
  {
    path: "/adjustment/admin/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/admin/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin"],
    exact: true
  },

  // modul pelarasan - pc - hadi
  {
    path: "/adjustment/pc",
    Component: loadable(() => import("pages/adjustment/pc")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpc"],
    exact: true
  },
  {
    path: "/adjustment/pc/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment/pc/invoice/invoice-view")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpc"],
    exact: true
  },
  {
    path: "/adjustment/swkerani",
    Component: loadable(() => import("pages/adjustment/swkerani")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swkerani"],
    exact: true
  },
  {
    path: "/adjustment/swkerani/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/swkerani/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swkerani"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/swkerani",
    Component: loadable(() => import("pages/adjustment-unscheduled/swkerani")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swkerani"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/swkerani/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/swkerani/edit")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swkerani"],
    exact: true
  },
  {
    path: "/adjustmentUpdate/swbkw",
    Component: loadable(() => import("pages/adjustmentUpdate/swbkw")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbkw", "swbahagian"],
    exact: true
  },
  {
    path: "/adjustmentUpdate/swbahagian",
    Component: loadable(() => import("pages/adjustmentUpdate/swbahagian")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbahagian"],
    exact: true
  },
  {
    path: "/adjustmentUnscheduledUpdate/swbkw",
    Component: loadable(() =>
      import("pages/adjustment-unscheduledUpdate/swbkw")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbkw", "swbahagian"],
    exact: true
  },
  {
    path: "/adjustmentUnscheduledUpdate/swbahagian",
    Component: loadable(() =>
      import("pages/adjustment-unscheduledUpdate/swbahagian")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbahagian"],
    exact: true
  },
  // modul pelarasan - konsesi - hadi
  {
    path: "/adjustment/konsesi",
    Component: loadable(() => import("pages/adjustment/konsesi")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["konsesikw", "konsesimm"],
    exact: true
  },
  {
    path: "/adjustment/konsesi/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment/konsesi/konsesi-review.js")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["konsesikw", "konsesimm"],
    exact: true
  },

  // PELARASAN PENALTI
  {
    path: "/adjustment-penalty/ppk",
    Component: loadable(() => import("pages/adjustment-penalty/ppk")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swppk"],
    exact: true
  },
  {
    path: "/adjustment-penalty/ppk/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-penalty/ppk/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swppk"],
    exact: true
  },
  {
    path: "/adjustment-penalty/pc",
    Component: loadable(() => import("pages/adjustment-penalty/pc")),
    roles: ["swpc"],
    exact: true
  },
  {
    path: "/adjustment-penalty/pc/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-penalty/pc/invoice/invoice-view")
    ),
    roles: ["swpc"],
    exact: true
  },
  {
    path: "/adjustment-penalty/pn",
    Component: loadable(() => import("pages/adjustment-penalty/pn")),
    roles: ["swpn"],
    exact: true
  },
  {
    path: "/adjustment-penalty/pn/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-penalty/pn/invoice-view")
    ),
    roles: ["swpn"],
    exact: true
  },
  {
    path: "/adjustment-penalty/bpk",
    Component: loadable(() => import("pages/adjustment-penalty/bpk")),
    roles: ["swpbpk"],
    exact: true
  },
  {
    path: "/adjustment-penalty/bpk/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-penalty/bpk/invoice-view")
    ),
    roles: ["swpbpk"],
    exact: true
  },
  {
    path: "/adjustment-penalty/konsesi",
    Component: loadable(() => import("pages/adjustment-penalty/konsesi")),
    roles: ["konsesikw", "konsesimm"],
    exact: true
  },
  {
    path: "/adjustment-penalty/konsesi/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-penalty/konsesi/konsesi-review.js")
    ),
    roles: ["konsesikw", "konsesimm"],
    exact: true
  },
  // pelarasan pic-bsd
  {
    path: "/adjustment/pic-bsd",
    Component: loadable(() => import("pages/adjustment/pic-bsd")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpicbsd"],
    exact: true
  },
  {
    path: "/adjustment/pic-bsd/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/pic-bsd/edit")),
    roles: ["swpicbsd"],
    exact: true
  },
  // pelarasan pic-btm
  {
    path: "/adjustment/pic-btm",
    Component: loadable(() => import("pages/adjustment/pic-btm")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpicbtm"],
    exact: true
  },
  {
    path: "/adjustment/pic-btm/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment/pic-btm/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpicbtm"],
    exact: true
  },
  // pelarasan swpn
  {
    path: "/adjustment/pn",
    Component: loadable(() => import("pages/adjustment/pn")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpn"],
    exact: true
  },

  // PELARASAN TIDAK BERJADUAL
  {
    path: "/adjustment-unscheduled/ppk",
    Component: loadable(() => import("pages/adjustment-unscheduled/ppk")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swppk"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/ppk/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/ppk/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swppk"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/admin",
    Component: loadable(() => import("pages/adjustment-unscheduled/admin")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/admin/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/admin/edit")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pc",
    Component: loadable(() => import("pages/adjustment-unscheduled/pc")),
    roles: ["swpc"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pc/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/pc/invoice/invoice-view")
    ),
    roles: ["swpc"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pn",
    Component: loadable(() => import("pages/adjustment-unscheduled/pn")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpn"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pn/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/pn/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpn"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pic-btm",
    Component: loadable(() => import("pages/adjustment-unscheduled/pic-btm")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpicbtm"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pic-btm/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/pic-btm/edit")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpicbtm"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/btm",
    Component: loadable(() => import("pages/adjustment-unscheduled/btm")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpbtm"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/btm/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/btm/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpbtm"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pic-bsd",
    Component: loadable(() => import("pages/adjustment-unscheduled/pic-bsd")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpicbsd"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/pic-bsd/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/pic-bsd/edit")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpicbsd"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/bsd",
    Component: loadable(() => import("pages/adjustment-unscheduled/bsd")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpbsd"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/bsd/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/bsd/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swpbsd"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/bkw",
    Component: loadable(() => import("pages/adjustment-unscheduled/bkw")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbkw", "swpbkw"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/swpbkw",
    Component: loadable(() => import("pages/adjustment-unscheduled/swpbkw")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpbkw"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/swbahagian",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/swbahagian")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbahagian"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/bkw/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/bkw/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swbkw", "swpbkw", "swbahagian"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/swpbkw/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/swpbkw/edit")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["swpbkw"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/tkpe",
    Component: loadable(() => import("pages/adjustment-unscheduled/tkpe")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swtkpe"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/tkpe/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/tkpe/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swtkpe"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/kpe",
    Component: loadable(() => import("pages/adjustment-unscheduled/kpe")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swkpe"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/kpe/:status/:refno/review",
    Component: loadable(() => import("pages/adjustment-unscheduled/kpe/edit")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["admin", "swkpe"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/konsesi",
    Component: loadable(() => import("pages/adjustment-unscheduled/konsesi")),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["konsesikw", "konsesimm"],
    exact: true
  },
  {
    path: "/adjustment-unscheduled/konsesi/:status/:refno/review",
    Component: loadable(() =>
      import("pages/adjustment-unscheduled/konsesi/konsesi-review.js")
    ),
    // Component: loadable(() => import('pages/adjustment/undermaintenance')),
    roles: ["konsesikw", "konsesimm"],
    exact: true
  }
];

const unAuthorisedRoutes = [
  // System Pages
  {
    path: "/system/register",
    Component: loadable(() => import("pages/system/register")),
    roles: [],
    exact: true
  },
  {
    path: "/system/lockscreen",
    Component: loadable(() => import("pages/system/lockscreen")),
    roles: [],
    exact: true
  },
  {
    path: "/system/forgot-password/:tokenId",
    Component: loadable(() => import("pages/system/forgot-password")),
    exact: true
  },
  {
    path: "/system/403",
    Component: loadable(() => import("pages/system/403")),
    exact: true
  },
  {
    path: "/system/404",
    Component: loadable(() => import("pages/system/404")),
    exact: true
  },
  {
    path: "/system/500",
    Component: loadable(() => import("pages/system/500")),
    exact: true
  }
];

const mapStateToProps = ({ settings, user }) => ({ settings, user });

@connect(mapStateToProps)
class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      debug: false
    };
    this.getSystemSetting();
  }

  async getSystemSetting() {
    const { data } = await axios.get(`/api/system_settings/getSystemSetting`);
    this.setState({
      ...data,
      loading: false
    });
  }

  render() {
    const {
      history,
      settings: { routerAnimation },
      user
    } = this.props;
    const { loading, debug, undermaintenance } = this.state;
    if (loading) return null;
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname }
              } = props;
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === "none" ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              );
            }}
          >
            {loading ? (
              <Route
                exact
                path="/system/login"
                key="/system/login"
                component={loadable(() => import("pages/system/loading"))}
              />
            ) : undermaintenance ? (
              <Route
                exact
                path="/system/login"
                key="/system/login"
                component={loadable(() =>
                  import("pages/system/undermaintenance")
                )}
              />
            ) : (
              <Route
                exact
                path="/system/login"
                key="/system/login"
                component={loadable(() => import("pages/system/login"))}
              />
            )}
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(({ path, Component, exact, roles }) => (
              <ProtectedRoute
                path={path}
                key={path}
                exact={exact}
                roles={roles}
                user={user}
                component={Component}
                debug={debug}
              />
            ))}
            {unAuthorisedRoutes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component {...this.props} />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    );
  }
}

export default Router;
