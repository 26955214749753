/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import {
  getConcessionYearlyInvoicesSvc,
  getConcessionMonthlyInvoicesSvc,
  getStateYearlyInvoicesSvc,
  getStateMonthlyInvoicesSvc,
  getPbtYearlyInvoicesSvc,
  getConcessionMonthInvoiceSvc,
  getStateYearInvoiceSvc,
  getPbtYearInvoiceSvc,
  getKpiCountAndPenaltyAmountSvc,
  getInvoiceAmountsSvc,
  getInvoiceDetailsSvc,
  getCumulativeInvoiceAmountSvc,
  getUserAccessLevelSvc,
} from 'services/chart'
import actions from './actions'

export function* getConcessionYearlyInvoices() {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionYearlyInvoices: {
          data: { labels: [], datasets: { 'Alam Flora': [], SWM: [], 'Environment Idaman': [] } },
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getConcessionYearlyInvoicesSvc)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionYearlyInvoices: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionYearlyInvoices: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getConcessionMonthlyInvoices() {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionMonthlyInvoices: {
          data: {},
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getConcessionMonthlyInvoicesSvc)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionMonthlyInvoices: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionMonthlyInvoices: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getStateYearlyInvoices(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateYearlyInvoices: {
          data: {},
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getStateYearlyInvoicesSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateYearlyInvoices: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateYearlyInvoices: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getStateMonthlyInvoices(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateMonthlyInvoices: {
          data: {
            invoiceAmounts: { labels: [], datasets: [] },
            penaltyAmounts: { labels: [], datasets: [] },
          },
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(
      getStateMonthlyInvoicesSvc,
      action.payload.concessionId,
    )
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateMonthlyInvoices: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateMonthlyInvoices: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getPbtYearlyInvoices(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        pbtYearlyInvoices: {
          isLoading: true,
          data: {},
        },
      },
    })
    const { response: success } = yield call(getPbtYearlyInvoicesSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        pbtYearlyInvoices: {
          isLoading: false,
          data: success,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        pbtYearlyInvoices: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getConcessionMonthInvoice(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionMonthInvoice: {
          data: {
            byActivities: { labels: [], datasets: [] },
            byCategories: { labels: [], datasets: [] },
            byPBTs: { labels: [], datasets: [] },
          },
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getConcessionMonthInvoiceSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionMonthInvoice: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        concessionMonthInvoice: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getStateYearInvoice(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateYearInvoice: {
          data: {},
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getStateYearInvoiceSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateYearInvoice: {
          data: {},
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        stateYearInvoice: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getPbtYearInvoice(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        pbtYearInvoice: {
          data: {},
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getPbtYearInvoiceSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        pbtYearInvoice: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        pbtYearInvoice: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getKpiCountAndPenaltyAmount(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        kpiCountAndPenaltyAmount: {
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getKpiCountAndPenaltyAmountSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        kpiCountAndPenaltyAmount: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        kpiCountAndPenaltyAmount: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getInvoiceAmounts(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        invoiceAmounts: { data: [], isLoading: true },
      },
    })
    const { response: success } = yield call(getInvoiceAmountsSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        invoiceAmounts: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        invoiceAmounts: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getInvoiceDetails(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        invoiceDetails: { data: [], isLoading: true },
      },
    })
    const { response: success } = yield call(getInvoiceDetailsSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        invoiceDetails: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        invoiceDetails: {
          isLoading: false,
        },
      },
    })
  }
}

export function* getCumulativeInvoiceAmount(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        cumulativeInvoiceAmounts: {
          data: {
            states: [],
          },
          isLoading: true,
        },
      },
    })
    const { response: success } = yield call(getCumulativeInvoiceAmountSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        cumulativeInvoiceAmounts: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        cumulativeInvoiceAmounts: {
          data: {
            states: [],
          },
          isLoading: false,
        },
      },
    })
  }
}

export function* getUserAccessLevel(action) {
  try {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        userAccessLevel: { data: [], isLoading: true },
      },
    })
    const { response: success } = yield call(getUserAccessLevelSvc, action.payload)
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        userAccessLevel: {
          data: success,
          isLoading: false,
        },
      },
    })
  } catch (e) {
    yield put({
      type: 'chart/SET_STATE',
      payload: {
        userAccessLevel: {
          isLoading: false,
        },
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_CONCESSION_YEARLY_INVOICES, getConcessionYearlyInvoices),
    takeLatest(actions.GET_CONCESSION_MONTHLY_INVOICES, getConcessionMonthlyInvoices),
    takeLatest(actions.GET_STATE_YEARLY_INVOICES, getStateYearlyInvoices),
    takeLatest(actions.GET_STATE_MONTHLY_INVOICES, getStateMonthlyInvoices),
    takeLatest(actions.GET_PBT_YEARLY_INVOICES, getPbtYearlyInvoices),
    takeLatest(actions.GET_CONCESSION_MONTH_INVOICE, getConcessionMonthInvoice),
    takeLatest(actions.GET_STATE_YEAR_INVOICE, getStateYearInvoice),
    takeLatest(actions.GET_PBT_YEAR_INVOICE, getPbtYearInvoice),
    takeLatest(actions.GET_KPI_COUNT_AND_PENALTY_AMOUNT, getKpiCountAndPenaltyAmount),
    takeLatest(actions.GET_INVOICE_AMOUNTS, getInvoiceAmounts),
    takeLatest(actions.GET_INVOICE_DETAILS, getInvoiceDetails),
    takeLatest(actions.GET_CUMULATIVE_INVOICE_AMOUNT, getCumulativeInvoiceAmount),
    takeLatest(actions.GET_USER_ACCESS_LEVEL, getUserAccessLevel),
  ])
}
