/* eslint-disable */
import axios from 'axios'

export default async function getMenuData() {
  const { data } = await axios.get(`/api/system_settings/getSystemSetting`)
  const { debug } = data

  return [
    // {
    //   category: true,
    //   title: 'Dashboard',
    //   roles: ['admin', 'swpegawai', 'konsesikw', 'konsesimm', 'swpc'],
    // },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
      roles: [
        'admin',
        'swpegawai',
        'swkerani',
        'konsesikw',
        'konsesimm',
        'swpc',
        'swppk',
        'swpn',
        'swpbtm',
        'swpbsd',
        'swpbkw',
        'swtkpe',
        'swkpe',
        'swpbpk',
        'swpicbtm',
        'swpicbsd',
        'swbkw',
        'swbahagian'
      ],
    },
    {
      category: true,
      title: 'Halaman',
      roles: [
        'admin',
        'swpegawai',
        'konsesikw',
        'konsesimm',
        'swpc',
        'swppk',
        'swkerani',
        'swpicbsd',
        'swpicbtm',
        'swpn',
        'swpbtm',
        'swbkw',
        'swpbkw',
        'swbahagian',
      ],
    },
    {
      title: 'Bayaran',
      key: 'system',
      icon: 'fe fe-file-text',
      roles: [
        'admin',
        'swpegawai',
        'konsesikw',
        'konsesimm',
        'swkerani',
        'swpc',
        'swppk',
        'afsb_payment',
        'swpicbsd',
        'swpicbtm',
        'swpn',
        'swpbtm',
        'swbkw',
        'swpbkw',
        'swbahagian',
      ],
      children: [
        {
          title: 'Tuntutan Invois (Konsesi)',
          key: 'paymentInvoice',
          url: '/payment/invoice',
          roles: ['admin', 'konsesimm','konsesikw'],
        },
        {
          title: 'Invois',
          key: 'paymentInvoiceVerify',
          url: '/payment/verifyInvoice',
          roles: ['admin', 'swpegawai', 'swpbkw', 'swbahagian'],
        },
         {
          title: 'Invois',
          key: 'paymentInvoiceVerify',
          url: '/payment/invoice',
          roles: ['swbkw'],
        },
        {
          title: 'Invois (PPK)',
          key: 'paymentInvoiceByPBT',
          url: '/payment/bypbt',
          roles: ['admin', 'swppk'],
        },
        {
          title: 'Invois (Penyelaras)',
          key: 'paymentInvoicePenyelarasByPBT',
          url: '/payment/bypbt',
          roles: ['swkerani'],
        },
        {
          title: 'Invois (PC)',
          key: 'paymentInvoicePCByPBT',
          url: '/payment/bypbt',
          roles: ['swpc'],
        },
        {
          title: 'Invois (PIC BSD)',
          key: 'paymentInvoiceBSDByPBT',
          url: '/payment/bypbt',
          roles: ['swpicbsd'],
        },
        {
          title: 'Invois (PIC BTM)',
          key: 'paymentInvoiceBTMByPBT',
          url: '/payment/bypbt',
          roles: ['swpicbtm'],
        },
        {
          title: 'Invois (Pengarah Negeri)',
          key: 'paymentInvoicePNByPBT',
          url: '/payment/bypbt',
          roles: ['swpn'],
        },
        {
          title: 'Invois (Pengarah BTM)',
          key: 'paymentInvoicePBTM',
          url: '/payment/invoice',
          roles: ['swpbtm'],
        },
      ],
    },
    {
      category: true,
      title: 'KWPSPA',
      roles: ['admin', 'swpegawai'],
    },
    {
      title: 'Laporan KWPSPA',
      key: 'laporan-kwpsppa',
      icon: 'fe fe-file-plus',
      url: '/kwpsppa/laporan',
      roles: ['admin', 'swpegawai'],
    },
    {
      category: true,
      title: 'Pelarasan Berjadual & Tidak Berjadual',
      roles: [
        'admin',
        'swpegawai',
        'konsesikw',
        'konsesimm',
        'swpc',
        'swppk',
        'swkerani',
        'swpicbsd',
        'swpicbtm',
        'swpn',
        'swpbtm',
        'swpbsd',
        'swbkw',
        'swbahagian'
      ],
    },
    {
      title: 'Pelarasan',
      key: 'pelarasan-swcorp',
      icon: 'fe fe-list',
      url: '/adjustment/swcorp',
      roles: [
        'admin',
        'swpegawai',
        'konsesikw',
        'konsesimm',
        'swpc',
        'swppk',
        'swkerani',
        'swpicbtm',
        'swpicbsd',
        'swpn',
        'swpbtm',
        'swpbsd',
        'swbkw',
        'swpbkw',
        'swbahagian',
      ],
      children: [
        {
          title: 'Pelarasan Berjadual (PPK)',
          key: 'pelarasan-ppk',
          // icon: 'fe fe-list',
          url: '/adjustment/ppk',
          roles: ['swppk'],
        },
        {
          title: 'Pelarasan Berjadual (PC)',
          key: 'pelarasan-pc',
          // icon: 'fe fe-list',
          roles: ['swpc'],
          url: '/adjustment/pc',
        },
        {
          title: 'Pelarasan Berjadual (Konsesi)',
          key: 'pelarasan-konsesi',
          // icon: 'fe fe-list',
          roles: ['konsesikw', 'konsesimm'],
          url: '/adjustment/konsesi',
        },
        {
          title: 'Pelarasan Berjadual (PIC BSD)',
          key: 'pelarasan-berjadual-pic-bsd',
          // icon: 'fe fe-calendar',
          url: '/adjustment/pic-bsd',
          roles: ['swpicbsd'],
          // roles: [debug && 'admin', 'swpicbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Berjadual (PIC BTM)',
          key: 'pelarasan-berjadual-pic-btm',
          // icon: 'fe fe-calendar',
          url: '/adjustment/pic-btm',
          roles: ['swpicbtm'],
          // roles: [debug && 'admin', 'swpicbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Berjadual (Penyelaras Negeri)',
          key: 'pelarasan-berjadual-swkerani',
          // icon: 'fe fe-calendar',
          url: '/adjustment/swkerani',
          roles: ['swkerani'],
        },
        {
          title: 'Pelarasan Berjadual (Pengarah Negeri)',
          key: 'pelarasan-berjadual-pn',
          // icon: 'fe fe-calendar',
          url: '/adjustment/pn',
          roles: ['swpn'],
          // roles: [debug && 'admin', 'swpicbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Berjadual (Admin)',
          key: 'pelarasan-berjadual-admin',
          // icon: 'fe fe-calendar',
          url: '/adjustment/admin',
          roles: ['admin',],
          // roles: [debug && 'admin', 'swpicbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Berjadual (Pengarah BTM)',
          key: 'pelarasan-berjadual-swpbtm',
          // icon: 'fe fe-calendar',
          url: '/adjustment/swpbtm',
          roles: ['swpbtm'],
          // roles: [debug && 'admin', 'swpicbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Berjadual (BKW)',
          key: 'pelarasan-berjadual-bkw',
          // icon: 'fe fe-calendar',
          url: '/adjustment/bkw',
          roles: ['swbkw'],
        },
         {
          title: 'Pelarasan Berjadual (UAD)',
          key: 'pelarasan-berjadual-swbahagian',
          // icon: 'fe fe-calendar',
          url: '/adjustment/swbahagian',
          roles: ['swbahagian'],
        },
        {
          title: 'Pelarasan Berjadual (PBKW)',
          key: 'pelarasan-berjadual-swpbkw',
          // icon: 'fe fe-calendar',
          url: '/adjustment/swpbkw',
          roles: ['swpbkw'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (Admin)',
          key: 'pelarasan-tidak-berjadual-admin',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/admin',
          roles: ['admin'],
          // roles: [debug && 'admin', 'swppk'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (PPK)',
          key: 'pelarasan-tidak-berjadual-ppk',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/ppk',
          roles: ['swppk'],
          // roles: [debug && 'admin', 'swppk'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (PC)',
          key: 'pelarasan-tidak-berjadual-pc',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/pc',
          roles: ['swpc'],
          // roles: [debug && 'admin', 'swpc'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (PN)',
          key: 'pelarasan-tidak-berjadual-pn',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/pn',
          roles: ['swpn'],
          // roles: [debug && 'admin', 'swpn'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (PIC BTM)',
          key: 'pelarasan-tidak-berjadual-pic-btm',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/pic-btm',
          roles: ['swpicbtm'],
          // roles: [debug && 'admin', 'swpicbtm'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (Pengarah BTM)',
          key: 'pelarasan-tidak-berjadual-pengarah-btm',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/btm',
          roles: ['swpbtm'],
          // roles: [debug && 'admin', 'swpbtm'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (PIC BSD)',
          key: 'pelarasan-tidak-berjadual-pic-bsd',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/pic-bsd',
          roles: ['swpicbsd'],
          // roles: [debug && 'admin', 'swpicbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (Pengarah BSD)',
          key: 'pelarasan-tidak-berjadual-pengarah-bsd',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/bsd',
          roles: ['swpbsd'],
          // roles: [debug && 'admin', 'swpbsd'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (BKW)',
          key: 'pelarasan-tidak-berjadual-bkw',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/bkw',
          roles: ['swbkw'],
          // roles: [debug && 'admin', 'swpbkw'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (UAD)',
          key: 'pelarasan-tidak-berjadual-swbahagian',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/swbahagian',
          roles: ['swbahagian'],
          // roles: [debug && 'admin', 'swpbkw'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (PBKW)',
          key: 'pelarasan-tidak-berjadual-pbkw',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/swpbkw',
          roles: ['swpbkw'],
          // roles: [debug && 'admin', 'swpbkw'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (TKPE)',
          key: 'pelarasan-tidak-berjadual-tkpe',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/tkpe',
          roles: ['swtkpe'],
          // roles: [debug && 'admin', 'swtkpe'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (KPE)',
          key: 'pelarasan-tidak-berjadual-kpe',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/kpe',
          roles: ['swkpe'],
          // roles: [debug && 'admin', 'swkpe'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (Konsesi)',
          key: 'pelarasan-tidak-berjadual-konsesi',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/konsesi',
          roles: ['konsesikw', 'konsesimm'],
          // roles: [debug && 'admin', 'konsesikw', 'konsesimm'],
          // roles: [debug && 'admin'],
        },
        {
          title: 'Pelarasan Tidak Berjadual (Penyelaras)',
          key: 'pelarasan-tidak-berjadual-swkerani',
          // icon: 'fe fe-calendar',
          url: '/adjustment-unscheduled/swkerani',
          roles: ['swkerani'],
          // roles: [debug && 'admin', 'konsesikw', 'konsesimm'],
          // roles: [debug && 'admin'],
        },
      ],
    },
    // Kewangan Kemaskini Status
    {
      category: true,
      title: 'Kemaskini Status Pelarasan',
      roles: ['swbkw'],
    },
    {
      title: 'Kemaskini Pelarasan',
      key: 'pelarasanKemaskini-swcorp',
      icon: 'fe fe-list',
      url: '/adjustmentUpdate/swcorp',
      roles: ['swbkw','swbahagian'],
      children: [
        {
          title: 'Kemaskini Pelarasan Berjadual',
          key: 'pelarasan-Update-swbkw',
          // icon: 'fe fe-list',
          url: '/adjustmentUpdate/swbkw',
          roles: ['swbkw'],
        },
        {
          title: 'Kemaskini Pelarasan Tidak Berjadual',
          key: 'pelarasan-Unscheduled-Update-swbkw',
          // icon: 'fe fe-list',
          url: '/adjustmentUnscheduledUpdate/swbkw',
          roles: ['swbkw'],
        },
         {
          title: 'Kemaskini Pelarasan Berjadual',
          key: 'pelarasan-Update-swbahagian',
          // icon: 'fe fe-list',
          url: '/adjustmentUpdate/swbahagian',
          roles: ['swbahagian'],
        },
        {
          title: 'Kemaskini Pelarasan Tidak Berjadual',
          key: 'pelarasan-Unscheduled-Update-swbahagian',
          // icon: 'fe fe-list',
          url: '/adjustmentUnscheduledUpdate/swbahagian',
          roles: ['swbahagian'],
        },
      ],
    },

    // START PELARASAN PENALTI
    // {
    //   category: true,
    //   title: 'Pelarasan Penalti',
    //   roles: ['admin', 'swpegawai', 'konsesikw', 'konsesimm', 'swpc', 'swppk', 'swpn', 'swpbpk'],
    //   // roles: ['swpegawai', 'swbkw'],
    // },
    // {
    //   title: 'Pelarasan Penalti (PPK)',
    //   key: 'pelarasan-penalti-ppk',
    //   icon: 'fe fe-alert-circle',
    //   url: '/adjustment-penalty/ppk',
    //   roles: [debug && 'admin', 'swppk'],
    //   // roles: [debug && 'admin'],
    // },
    // {
    //   title: 'Pelarasan Penalti (PC)',
    //   key: 'pelarasan-penalti-pc',
    //   icon: 'fe fe-alert-circle',
    //   url: '/adjustment-penalty/pc',
    //   roles: [debug && 'admin', 'swpc'],
    //   // roles: [debug && 'admin'],
    // },
    // {
    //   title: 'Pelarasan Penalti (PN)',
    //   key: 'pelarasan-penalti-pn',
    //   icon: 'fe fe-alert-circle',
    //   url: '/adjustment-penalty/pn',
    //   roles: [debug && 'admin', 'swpn'],
    //   // roles: [debug && 'admin'],
    // },
    // {
    //   title: 'Pelarasan Penalti (BPK/C4i)',
    //   key: 'pelarasan-penalti-bpkc4i',
    //   icon: 'fe fe-alert-circle',
    //   url: '/adjustment-penalty/bpk',
    //   roles: [debug && 'admin', 'swpbpk'],
    // },
    // {
    //   title: 'Pelarasan Penalti (Konsesi)',
    //   key: 'pelarasan-penalti-konsesi',
    //   icon: 'fe fe-alert-circle',
    //   roles: [debug && 'admin'],
    //   // roles: [debug && 'admin', 'konsesikw', 'konsesimm'],
    //   url: '/adjustment-penalty/konsesi',
    // },
    // END PELARASAN PENALTI
    // {
    //   category: true,
    //   title: 'Pelarasan Tidak Berjadual',
    //   roles: [
    //     // debug && 'admin',
    //     'admin',
    //     'swpegawai',
    //     'konsesikw',
    //     'konsesimm',
    //     'swpc',
    //     'swppk',
    //     'swpn',
    //     'swpbtm',
    //     'swpbsd',
    //     'swpbkw',
    //     'swtkpe',
    //     'swkpe',
    //   ],
    // },

    {
      category: true,
      title: 'Lain-lain',
      roles: ['admin', 'swpegawai', 'konsesikw', 'konsesimm', 'swpc', 'swbkw'],
    },
    // {
    //   title: 'Pelarasan',
    //   key: 'adjustment',
    //   icon: 'fe fe-plus-square',
    //   roles: ['admin', 'konsesikw', 'swpegawai', 'swpc'],
    //   children: [],
    // },
    {
      title: 'KWSPPA',
      key: 'kwsppa',
      icon: 'fe fe-book',
      roles: ['admin', 'swpegawai', 'swpc', 'swbkw'],
      children: [
        {
          title: 'Laporan',
          key: 'laporankwsppa',
          url: '/kwsppa/laporan',
          roles: ['admin', 'swpegawai', 'swpc', 'swbkw'],
        },
      ],
    },
    {
      title: 'Konfigurasi',
      key: 'configuration',
      icon: 'fe fe-settings',
      roles: ['admin', 'swpegawai', 'swbkw'],
      children: [
        {
          title: 'Pengguna Sistem',
          key: 'pengguna',
          url: '/configuration/users',
          roles: ['admin', 'bkw'],
        },
        {
          title: 'Organisasi & Konsesi',
          key: 'concession',
          url: '/configuration/organization',
          roles: ['admin', 'swpegawai', 'swbkw'],
        },
        {
          title: 'Kadar',
          key: 'rates',
          url: '/configuration/rates',
          roles: ['admin', 'swpegawai'],
        },
        // {
        //   title: 'Cukai (SST)',
        //   key: 'taxes',
        //   url: '/configuration/tax',
        //   roles: ['admin', 'swpegawai'],
        // },
      ],
    },
    {
      title: 'Rujukan',
      key: 'references',
      icon: 'fe fe-folder',
      roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc', 'swbkw'],
      children: [
        {
          title: 'Aktiviti',
          key: 'aktivity',
          url: '/references/activity',
          roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc', 'swbkw'],
        },
        {
          title: 'Taman',
          key: 'park',
          url: '/references/parks',
          roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc'],
        },
        {
          title: 'PBT',
          key: 'pbt',
          url: '/references/pbt',
          roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc'],
        },
        {
          title: 'Negeri',
          key: 'state',
          url: '/references/state',
          roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc', 'swbkw'],
        },
        {
          title: 'Skim',
          key: 'scheme',
          url: '/references/scheme',
          roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc', 'swbkw'],
        },
        {
          title: 'Jalan',
          key: 'street',
          url: '/references/street',
          roles: ['admin', 'swpegawai', 'konsesimm', 'konsesikw', 'swpc', 'swbkw'],
        },
      ],
    },
    // {
    //   title: 'System Pages',
    //   key: 'system',
    //   icon: 'fe fe-user',
    //   children: [
    //     {
    //       title: 'Login',
    //       key: 'systemLogin',
    //       url: '/system/login',
    //     },
    //     {
    //       title: 'Forgot Password',
    //       key: 'systemForgotPassword',
    //       url: '/system/forgot-password',
    //     },
    //     {
    //       title: 'Register',
    //       key: 'systemRegister',
    //       url: '/system/register',
    //     },
    //     {
    //       title: 'Lockscreen',
    //       key: 'systemLockscreen',
    //       url: '/system/lockscreen',
    //     },
    //     {
    //       title: 'Page 404',
    //       key: 'system404',
    //       url: '/system/404',
    //     },
    //     {
    //       title: 'Page 500',
    //       key: 'system500',
    //       url: '/system/500',
    //     },
    //   ],
    // },
    // {
    //   title: 'Ecommerce',
    //   key: 'ecommerce',
    //   icon: 'fe fe-shopping-cart',
    //   children: [
    //     {
    //       title: 'Dashboard',
    //       key: 'ecommerceDashboard',
    //       url: '/ecommerce/dashboard',
    //     },
    //     {
    //       title: 'Orders',
    //       key: 'ecommerceOrders',
    //       url: '/ecommerce/orders',
    //     },
    //     {
    //       title: 'Propduct Catalog',
    //       key: 'ecommerceProductCatalog',
    //       url: '/ecommerce/product-catalog',
    //     },
    //     {
    //       title: 'Product Details',
    //       key: 'ecommerceProductDetails',
    //       url: '/ecommerce/product-details',
    //     },
    //     {
    //       title: 'Cart',
    //       key: 'ecommerceCart',
    //       url: '/ecommerce/cart',
    //     },
    //   ],
    // },
    // {
    //   title: 'Apps',
    //   key: 'apps',
    //   icon: 'fe fe-database',
    //   children: [
    //     {
    //       title: 'Messaging',
    //       key: 'appsCart',
    //       url: '/apps/messaging',
    //     },
    //     {
    //       title: 'Calendar',
    //       key: 'appsCalendar',
    //       url: '/apps/calendar',
    //     },
    //     {
    //       title: 'Mail',
    //       key: 'appsMail',
    //       url: '/apps/mail',
    //     },
    //     {
    //       title: 'Profile',
    //       key: 'appsProfile',
    //       url: '/apps/profile',
    //     },
    //     {
    //       title: 'Gallery',
    //       key: 'appsGallery',
    //       url: '/apps/gallery',
    //     },
    //   ],
    // },
    // {
    //   title: 'Extra Apps',
    //   key: 'extraApps',
    //   icon: 'fe fe-hard-drive',
    //   children: [
    //     {
    //       title: 'Github Explore',
    //       key: 'extraAppsGithubExplore',
    //       url: '/extra-apps/github-explore',
    //     },
    //     {
    //       title: 'Github Discuss',
    //       key: 'extraAppsGithubDiscuss',
    //       url: '/extra-apps/github-discuss',
    //     },
    //     {
    //       title: 'Digitalocean Droplets',
    //       key: 'extraAppsDigitaloceanDroplets',
    //       url: '/extra-apps/digitalocean-droplets',
    //     },
    //     {
    //       title: 'Digitalocean Create',
    //       key: 'extraAppsDigitaloceanCreate',
    //       url: '/extra-apps/digitalocean-create',
    //     },
    //     {
    //       title: 'Google Analytics',
    //       key: 'extraAppsGoogleAnalytis',
    //       url: '/extra-apps/google-analytics',
    //     },
    //     {
    //       title: 'Wordpress Post',
    //       key: 'extraAppsWordpressPost',
    //       url: '/extra-apps/wordpress-post',
    //     },
    //     {
    //       title: 'Wordpress Posts',
    //       key: 'extraAppsWordpressPosts',
    //       url: '/extra-apps/wordpress-posts',
    //     },
    //     {
    //       title: 'Wordpress Add',
    //       key: 'extraAppsWordpressAdd',
    //       url: '/extra-apps/wordpress-add',
    //     },
    //     {
    //       title: 'Todoist List',
    //       key: 'extraAppsTodoistList',
    //       url: '/extra-apps/todoist-list',
    //     },
    //     {
    //       title: 'Jira Dashboard',
    //       key: 'extraAppsJiraDashboard',
    //       url: '/extra-apps/jira-dashboard',
    //     },
    //     {
    //       title: 'Jira Agile Board',
    //       key: 'extraAppsJiraAgileBoard',
    //       url: '/extra-apps/jira-agile-board',
    //     },
    //     {
    //       title: 'Helpdesk Dashboard',
    //       key: 'extraAppsHelpdeskDashboard',
    //       url: '/extra-apps/helpdesk-dashboard',
    //     },
    //   ],
    // },
    // {
    //   category: true,
    //   title: 'Components',
    // },
    // {
    //   title: 'Bootstrap',
    //   key: 'bootstrap',
    //   icon: 'fe fe-bookmark',
    //   url: '/ui-kits/bootstrap',
    // },
    // {
    //   title: 'Ant Design',
    //   key: 'antDesign',
    //   icon: 'fe fe-bookmark',
    //   url: '/ui-kits/antd',
    // },
    // {
    //   title: 'Widgets',
    //   key: 'widgets',
    //   icon: 'fe fe-image',
    //   count: 47,
    //   children: [
    //     {
    //       title: 'General',
    //       key: 'widgetsGeneral',
    //       url: '/widgets/general',
    //     },
    //     {
    //       title: 'Lists',
    //       key: 'widgetsLists',
    //       url: '/widgets/lists',
    //     },
    //     {
    //       title: 'Tables',
    //       key: 'widgetsTables',
    //       url: '/widgets/tables',
    //     },
    //     {
    //       title: 'Charts',
    //       key: 'widgetsCharts',
    //       url: '/widgets/charts',
    //     },
    //   ],
    // },
    // {
    //   title: 'Cards',
    //   key: 'cards',
    //   icon: 'fe fe-credit-card',
    //   children: [
    //     {
    //       title: 'Basic Cards',
    //       key: 'cardsBasicCards',
    //       url: '/cards/basic-cards',
    //     },
    //     {
    //       title: 'Tabbed Cards',
    //       key: 'cardsTabbedCards',
    //       url: '/cards/tabbed-cards',
    //     },
    //   ],
    // },
    // {
    //   title: 'Tables',
    //   key: 'tables',
    //   icon: 'fe fe-pie-chart',
    //   children: [
    //     {
    //       title: 'Bootstrap',
    //       key: 'tablesBootstrap',
    //       url: '/tables/bootstrap',
    //     },
    //     {
    //       title: 'Ant Design',
    //       key: 'tablesAntd',
    //       url: '/tables/antd',
    //     },
    //   ],
    // },
    // {
    //   title: 'Charts',
    //   key: 'charts',
    //   icon: 'fe fe-home',
    //   children: [
    //     {
    //       title: 'Chartist.js',
    //       key: 'chartsChartistjs',
    //       url: '/charts/chartistjs',
    //     },
    //     {
    //       title: 'Chart.js',
    //       key: 'chartsChartjs',
    //       url: '/charts/chartjs',
    //     },
    //     {
    //       title: 'C3',
    //       key: 'chartsC3',
    //       url: '/charts/c3',
    //     },
    //   ],
    // },
    // {
    //   title: 'Icons',
    //   key: 'icons',
    //   icon: 'fe fe-star',
    //   children: [
    //     {
    //       title: 'Feather Icons',
    //       key: 'iconsFeatherIcons',
    //       url: '/icons/feather-icons',
    //     },
    //     {
    //       title: 'Fontawesome',
    //       key: 'iconsFontawesome',
    //       url: '/icons/fontawesome',
    //     },
    //     {
    //       title: 'Linearicons Free',
    //       key: 'iconsLineariconsFree',
    //       url: '/icons/linearicons-free',
    //     },
    //     {
    //       title: 'Icomoon Free',
    //       key: 'iconsIcomoonFree',
    //       url: '/icons/icomoon-free',
    //     },
    //   ],
    // },
    // {
    //   category: true,
    //   title: 'Advanced',
    // },
    // {
    //   title: 'Form Examples',
    //   key: 'formExamples',
    //   icon: 'fe fe-menu',
    //   url: '/advanced/form-examples',
    // },
    // {
    //   title: 'Email Templates',
    //   key: 'emailTemplates',
    //   icon: 'fe fe-mail',
    //   url: '/advanced/email-templates',
    // },
    // {
    //   title: 'Pricing Tables',
    //   key: 'pricingTables',
    //   icon: 'fe fe-command',
    //   url: '/advanced/pricing-tables',
    // },
    // {
    //   title: 'Invoice',
    //   key: 'invoice',
    //   icon: 'fe fe-file-text',
    //   url: '/advanced/invoice',
    // },
    // {
    //   title: 'Utilities',
    //   key: 'utilities',
    //   icon: 'fe fe-inbox',
    //   url: '/advanced/utilities',
    // },
    // {
    //   title: 'Grid',
    //   key: 'grid',
    //   icon: 'fe fe-grid',
    //   url: '/advanced/grid',
    // },
    // {
    //   title: 'Typography',
    //   key: 'typography',
    //   icon: 'fe fe-type',
    //   url: '/advanced/typography',
    // },
  ]
}
