import axios from 'axios'
import {
  getPaymentURL,
  updateInvoiceRefNoPaymentURL,
  verifyInvoiceByKewURL,
  // azri training
  insertPaymentURL,
  deletePaymentURL,
  updatePaymentURL,
} from './apiUrl'

export default {}

export async function getInvoice() {
  return new Promise((resolve, reject) => {
    axios
      .get(getPaymentURL)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function updateInvoiceRefNoPaymentSvc(obj) {
  return new Promise((resolve, reject) => {
    axios
      .post(updateInvoiceRefNoPaymentURL, obj)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function verifyInvoiceByKewSvc(invoiceId) {
  return new Promise((resolve, reject) => {
    axios
      .patch(verifyInvoiceByKewURL + invoiceId, { invoice_status: 2 })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

// azri training
export async function insertPaymentSvc(invoiceId, invoiceNombor, invoiceAmount, invoiceStatus) {
  // console.log('table payment.js', invoiceId, invoiceNombor, invoiceAmount, invoiceStatus)
  return new Promise((resolve, reject) => {
    axios
      .post(insertPaymentURL, {
        id: invoiceId,
        invoice_no: invoiceNombor,
        invoice_amount_with_sst: invoiceAmount,
        invoice_status: invoiceStatus,
      })
      // console
      //   .log(
      //     'id:',
      //     invoiceId,
      //     'invoice_no:',
      //     invoiceNombor,
      //     'invoice_amount_with_sst:',
      //     invoiceAmount,
      //     'invoice_status:',
      //     invoiceStatus,
      //   )
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function deletePaymentSvc(invoiceId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(deletePaymentURL + invoiceId)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}

export async function updatePaymentSvc(invoiceId, invoiceNombor, invoiceAmount, invoiceStatus) {
  // console.log('table payment.js', invoiceId, invoiceNombor, invoiceAmount, invoiceStatus)
  return new Promise((resolve, reject) => {
    axios
      .patch(updatePaymentURL + invoiceId, {
        invoice_no: invoiceNombor,
        invoice_amount_with_sst: invoiceAmount,
        invoice_status: invoiceStatus,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response))
  })
}
