const actions = {
  SET_STATE: 'payment/SET_STATE',
  GET_INVOICE: 'payment/GET_INVOICE',
  UPDATE_INVOICE_REFNO: 'payment/UPDATE_INVOICE_REFNO',
  VERIFY_INVOICE_BY_KEW: 'payment/VERIFY_INVOICE_BY_KEW',
  // azri training
  DELETE_INVOIS: 'payment/DELETE_INVOIS',
  UPDATE_INVOIS: 'payment/UPDATE_INVOIS',
  INSERT_INVOIS: 'payment/INSERT_INVOIS',
}

export default actions
